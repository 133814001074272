<template>
	<transition name="page-fade" mode="out-in">
		<div key="main" :class="$style.pageWrapper" class="profile-main-wrapper">
			<app-header/>
			<main :class="$style.main">
				<transition name="page-fade" mode="out-in">
					<router-view class="os-viewport"/>
				</transition>
			</main>
			<app-footer v-if="this.$route.name !== 'Auth' && this.$route.name === 'listCr' "/>
            <notifications group="foo" />
		</div>
	</transition>
</template>

<script>
import appFooter from './components/AppFooter/index'
import appHeader from './components/AppHeader/index'

export default {
	components: {
        appFooter,
        appHeader
    }
}
</script>


<style src="./style/_style.less" lang="less" module/>