import orderBy from "lodash.orderby";
export default {
  namespaced: true,
  state: {
    code: "",
  },
  getters: {
    getCode: (state) => state.code,
  },
  mutations: {
    setCode(state, data) {
      console.log(data);
      state.code = data;
    },
  },
};
