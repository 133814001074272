import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "listCr",
    component: () => import("../views/ListCr/listCr.vue"),
  },
  {
    path: "/viewCR/:id",
    name: "ViewCR",
    component: () => import("../views/ViewCr/viewCr.vue"),
  },
  {
    path: "/subscription-first",
    name: "subscription",
    component: () => import("../views/Subscription/subscription1.vue"),
  },
  {
    path: "/subscription-second/:email",
    name: "subscription",
    component: () => import("../views/Subscription/subscription2.vue"),
  },
  {
    path: "/subscription-third/:email/:code",
    name: "subscription",
    component: () => import("../views/Subscription/subscription3.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
